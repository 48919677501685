import { CourseDto } from '../types'
import CarIcon from '../assets/icons/carWithGradient.svg'
import DrivingIcon from '../assets/icons/driving.svg'
import PoliceIcon from '../assets/icons/police.svg'

export const courses: CourseDto[] = [
    {
        title: 'SKUPINA',
        subtitle: 'B',
        subSubtitle: 'Klasik',
        duration: '2 až 3 měsíce',
        price: '18 000,- Kč',
        shortDescription:
            'Opravňuje k řízení motorových vozidel do celkové hmotnosti 3,5 tuny a max. počtu 8 cestujících plus řidič',
        description:
            'Typ řidičského průkazu: Skupina B \n Co řidičský průkaz skupiny B umožňuje: Opravňuje k řízení motorových vozidel do celkové hmotnosti 3,5 tuny s nejvýše 8 cestujícími plus řidič a s nebrzděným přívěsem do 750 kg. Dále umožňuje řídit motocykly s automatickou převodovkou a zdvihovým objemem do 125 m3. \n Délka výcviku: Obvykle 2 až 3 měsíce, záleží na časových možnostech žadatele. \n Zkouška: Zkouška se skládá ze dvou částí, \n teoretické zkoušky (test s 25 otázkami o předpisech o provozu na pozemních komunikacích) a \n praktické zkoušky (minimálně 30 minut jízdy v běžném městském provozu). Žadatel musí mít platný občanský průkaz a skládá zkoušku na Magistrátu města Chomutova. Poplatek magistátu za připuštění ke zkoušce je 700 Kč, za opakování písemného testu 100 Kč, za opakování praktické zkoušky je 400 Kč. Poplatek autoškole za každé opakování zkoušky činí 300,- Kč. \n \n\n  Teoretická část výcviku v délce 36 hodin zahrnuje:\n pravidla o provozu vozidel na pozemních komunikacích,\n  ovládání a údržbu vozidla,\n  zásady bezpečné jízdy a teorii jízdy,\n  zdravotní přípravu,\n opakování a přezkoušení.\n \n Praktická část výcviku v délce 34 hodin zahrnuje:\n řízení vozidla ve 3 etapách - za nízkého, středního, městského provozu,\n praktickou údržbu vozidla.\n \n Za zrušení jízdy méně než 24 hodin předem účtujeme poplatek 100,-Kč, při zrušení hodinu a méně před jízdou účtujeme 200,-Kč.',
        image: CarIcon,
    },
    {
        title: 'SKUPINA',
        subtitle: 'B',
        subSubtitle: 'Automat',
        duration: '2 až 3 měsíce',
        price: '18 000,- Kč',
        shortDescription:
            'Opravňuje pouze k řízení motorových vozidel s automatickou převodovkou do celkové hmotnosti 3,5 tuny a max. počtu 8 cestujících plus řidič',
        description:
            'Typ řidičského průkazu: Skupina B \n Co řidičský průkaz skupiny B umožňuje: Opravňuje k řízení pouze motorových vozidel z automatickou převodovkou do celkové hmotnosti 3,5 tuny s nejvýše 8 cestujícími plus řidič a s nebrzděným přívěsem do 750 kg. Dále umožňuje řídit motocykly s automatickou převodovkou a zdvihovým objemem do 125 m3. \n Délka výcviku: Obvykle 2 až 3 měsíce, záleží na časových možnostech žadatele. \n Zkouška: Zkouška se skládá ze dvou částí, \n teoretické zkoušky (test s 25 otázkami o předpisech o provozu na pozemních komunikacích) a \n praktické zkoušky (minimálně 30 minut jízdy v běžném městském provozu). Žadatel musí mít platný občanský průkaz a skládá zkoušku na Magistrátu města Chomutova. Poplatek autoškole za každé opakování zkoušky činí 300,- Kč. \n \n\n  Teoretická část výcviku v délce 36 hodin zahrnuje:\n pravidla o provozu vozidel na pozemních komunikacích,\n  ovládání a údržbu vozidla,\n  zásady bezpečné jízdy a teorii jízdy,\n  zdravotní přípravu,\n opakování a přezkoušení.\n \n Praktická část výcviku v délce 34 hodin zahrnuje:\n řízení vozidla ve 3 etapách - za nízkého, středního, městského provozu,\n praktickou údržbu vozidla.\n \n Za zrušení jízdy méně než 24 hodin předem účtujeme poplatek 100,-Kč, při zrušení hodinu a méně před jízdou účtujeme 200,-Kč.',
        image: CarIcon,
    },
    {
        title: 'KONDIČNÍ JÍZDY',
        subSubtitle: 'Skupina B',
        duration: '45 min',
        price: '400,- Kč za hodinu',
        shortDescription:
            'Potřebujete-li si zdokonalit řidičské schopnosti nebo jste delší dobu neřídili, nabízíme kondiční hodiny pod dohledem instruktora.',
        description:
            'Potřebujete-li si zdokonalit řidičské schopnosti nebo jste delší dobu neřídili, nabízíme kondiční hodiny v délce 45 minut pod dohledem instruktora. Podmínkou je aktuální účast ve výcviku nebo vlastnictví příslušného řidičského oprávnění. Počet kondičních jízd a typ trasy si vyberete podle Vašich potřeb. Cena za 1 kondiční hodinu je 400,- Kč.',
        image: DrivingIcon,
    },
    {
        title: 'Přezkoušení z odborné způsobilosti',
        price: '4 000,- Kč',
        shortDescription:
            'Vrácení řidičského průkazu sk. B po vybodování, po odebrání ze zdravotních důvodů nebo jiného uloženého zákazu řízení.',
        description:
            'Vrácení řidičského průkazu sk. B po vybodování, po odebrání ze zdravotních důvodů nebo jiného uloženého zákazu řízení. Potřebujete-li získat zpět řidičské oprávnění sk. B, nabízíme Vám možnost přípravy a zajištění přezkoušení včetně veškeré administrativy spojené se zkouškou.',
        image: PoliceIcon,
    },
]
