import React from 'react'

const Footer = () => {
    return (
        <div className='w-full flex flex-col justify-center items-center mt-[1px] p-10 border-primary border-t-8 text-secondaryDark'>
            <p>
                Copyright 2023 autoskolaerko.cz
            </p>
            <p>
                Vytvořil{' '}
                <a
                    href='https://shellma.net/'
                    target='_blank'
                    rel='noreferrer'
                    className='text-primary'
                >
                    Matěj Šela
                </a>
            </p>
        </div>
    )
}

export default Footer