import React from 'react'
import { ReactComponent as Wallet } from '../assets/icons/wallet-money.svg'
import { ReactComponent as Clock } from '../assets/icons/clock.svg'
import CarIcon from '../assets/icons/carWithGradient.svg'
import Driving from '../assets/icons/driving.svg'
import PoliceIcon from '../assets/icons/police.svg'
import { motion, Variants } from 'framer-motion'
import { useInView } from 'react-intersection-observer';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import Tilt from 'react-tilt'
import { CourseDto } from '../types';
import { Button, Modal } from '@mui/material'

interface CourseCardProps {
    course: CourseDto
}

const CourseCard: React.FC<CourseCardProps> = ({ course }: CourseCardProps) => {
    const [open, setOpen] = React.useState<boolean>(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const isTabletOrMobile = window.matchMedia("(max-width: 1024px)").matches;

    const [ref, inView] = useInView({
        threshold: 0,
        rootMargin: '0px',
        triggerOnce: true
    });

    const variants = {
        visible: { opacity: 1, scale: 1 },
        hidden: { opacity: 0, scale: 0.5 }
    };

    return (
        <>
            <Tilt>
                <motion.div
                    ref={ref}
                    variants={variants}
                    initial={inView ? "visible" : "hidden"}
                    animate={inView ? "visible" : "hidden"}
                    transition={isTabletOrMobile ? {} : { type: "spring", stiffness: 400, damping: 10 }}
                    className='bg-background w-72 h-72 flex items-center rounded-xl flex-col justify-between relative overflow-hidden hover:cursor-pointer'
                    onClick={handleOpen}>
                    <div className='w-full h-full flex flex-col justify-end p-3 z-20 space-y-3'>
                        <div>
                            {course.subSubtitle && <p className='text-secondaryDark'>{course.subSubtitle}</p>}
                            <div className='w-full flex space-x-2.5'><h6 className='font-bold text-3xl text-secondary'>{course.title}</h6> <h6 className='font-bold text-3xl text-primary'>{course?.subtitle}</h6></div>
                        </div>
                        {(course.price || course.duration) &&
                            <div className='w-full flex space-x-2.5'>
                                {course.price &&
                                    <div className='flex space-x-2'>
                                        <Wallet />
                                        <h6 className=' text-secondary'>{course.price}</h6>
                                    </div>
                                }
                                {course.duration &&
                                    <div className='flex space-x-1'>
                                        <Clock />
                                        <h6 className='text-secondary'>{course.duration}</h6>
                                    </div>
                                }
                            </div>
                        }
                        <p className='text-secondary'>{course.shortDescription}</p>
                    </div>
                    <div style={{ backgroundColor: 'rgba(30, 30, 30, 0.5)', }} className='absolute w-full h-full z-10 rounded-xl' />
                    <div className='absolute z-0 w-full h-full blur-[4px]' >
                        {/* <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="#D02ED0" style={{ filter: 'drop-shadow(0px 0 30px #bf3dc9)' }}><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M16,6l3,4h2c1.11,0,2,0.89,2,2v3h-2c0,1.66-1.34,3-3,3s-3-1.34-3-3H9c0,1.66-1.34,3-3,3s-3-1.34-3-3H1v-3c0-1.11,0.89-2,2-2 l3-4H16 M10.5,7.5H6.75L4.86,10h5.64V7.5 M12,7.5V10h5.14l-1.89-2.5H12 M6,13.5c-0.83,0-1.5,0.67-1.5,1.5s0.67,1.5,1.5,1.5 s1.5-0.67,1.5-1.5S6.83,13.5,6,13.5 M18,13.5c-0.83,0-1.5,0.67-1.5,1.5s0.67,1.5,1.5,1.5s1.5-0.67,1.5-1.5S18.83,13.5,18,13.5z"></path> <rect fill="none" width="24" height="24"></rect> </g></svg> */}
                        <img src={course.image} style={{ filter: 'drop-shadow(0px 0 30px #bf3dc9)' }} loading='lazy' />

                    </div>

                </motion.div>
            </Tilt>

            <Modal
                open={open}
                onClose={handleClose}
            >
                <div style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '80vw',
                    maxWidth: 920,
                    height: '85vh',
                    overflow: 'auto',
                    backgroundColor: '#241823',
                    color: '#FFF6FF',
                    padding: 20,
                    borderRadius: 20,
                    boxShadow: '0px 0px 30px rgba(191, 61, 201, 0.8)'
                }}>

                    <div className='absolute text-3xl text-gray-300 right-8 cursor-pointer'>
                        <span onClick={handleClose}>X</span>
                    </div>
                    <div className='flex flex-col'>
                        <p className='
                        text-secondary
                        text-5xl
                        font-bold
                    '>
                            {course.title} {course?.subtitle}
                        </p>
                        <p className=' text-gray-400 text-xl mb-5'>
                            {course.subSubtitle}
                        </p>
                    </div>
                    <p className='text-center'>
                        {
                            course.description.split('\n').map((item, key) => {
                                return <span key={key}>{item}<br /></span>
                            }
                            )
                        }
                    </p>
                </div>
            </Modal >
        </>
    )
}

export default CourseCard